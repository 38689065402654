import { createRouter, createWebHashHistory } from 'vue-router'
import news from '../views/news.vue'
// import heatmap from '../views/heatmap.vue'
// import $ from 'jquery'
 
// import { AuthenticationGuard } from 'vue-auth0-plugin';

const routes = [
  {
    path: '',
    name: 'default',
    meta: { gtm: 'heatmap' },
    component: () => import(/* webpackChunkName: "heatmap" */'../views/heatmap.vue')
    // redirect: '/heatmap'
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: { gtm: 'News' },
    id: "News"
  },
  {
    path: '/heatmap',
    name: 'Heatmap',
    meta: { gtm: 'heatmap' },
    component: () => import(/* webpackChunkName: "heatmap" */'../views/heatmap.vue')
  },
  // {
  //   path: '/oob',
  //   name: 'Open Order Book',
  //   meta: { gtm: 'Open Order Book' },
  //   component: () => import(/* webpackChunkName: "oob" */'../views/oob2.vue')
  //   // beforeEnter: AuthenticationGuard
  // },
  {
    path: '/usdoob',
    name: 'USD Open Order Book',
    component: () => import(/* webpackChunkName: "usdoob" */ '../views/oobfilter.vue')
    // beforeEnter: AuthenticationGuard
  },
  //  {
  //   path: '/chart',
  //   name: 'chart',
  //   component: () => import('../views/oprChart.vue')
  //   },
    {
      path: '/chartPlot',
      name: 'chartPlot',
      meta: { gtm: 'ChartPlot' },
      component: () => import(/* webpackChunkName: "imgChart" */ '../views/imgChart.vue')
      // beforeEnter: AuthenticationGuard,
      },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/hunt',
    name: 'Stop Loss Hunter',
    component: () => import(/* webpackChunkName: "hunter" */ '../views/hunt.vue')
  },
  {
    path: '/oprCycle',
    name: 'OPR Cycle',
    component: () => import(/* webpackChunkName: "hunter" */ '../views/oprCycle.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// router.beforeEach(() => {
//     $('.navbar-collapse').hide.bs.collapse
// })
router.beforeEach(() => {
  document.getElementById('navbar01').classList.remove('show');
})
export default router
