<template>
    <!-- <div class="fluid-container footer"> -->
    <footer class="fluid-container footer">
        Copyright &copy; {{new Date().getFullYear()}} HeatMapFX, LLC  
        <router-link class="nav-link active" :to="{ path: 'about', hash: '#disclaimer' }">Disclaimer</router-link>
        </footer>
     <!-- </div> -->
</template>

<script>
export default {
    name: 'footercomponent',
};
</script>

<style>
    .fluid-container.footer{
  background: rgb(74, 169, 233);
  color: #fff;
}

</style>